<template>
  <v-dialog v-model="showing" max-width="85%">
    <v-card>
      <v-card-title class="headline" primary-title>
        Kanal data
      </v-card-title>

      <v-card-text v-if="stats && value">
        <v-container grid-list-lg fluid p-0>
          <v-layout wrap row>
            <v-flex xs12 sm9>
              <h3 class="headline">Kategorier</h3>

              <v-layout row wrap>
                <v-flex
                  v-for="category in value.categories"
                  :key="category.id"
                  xs12
                  sm12
                  md4
                >
                  <v-chip outlined style="width:100%" disabled color="primary">
                    <v-avatar>
                      <v-icon class="ml-2">{{ category.icon }}</v-icon>
                    </v-avatar>

                    {{ category.display_name }}
                  </v-chip>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex md6 sm6 xs12>
              <stat-widget
                :icon="value.channel.icon"
                :title="value.followers | local_numbers"
                subtitle="Følgere"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex md6 sm6 xs12>
              <stat-widget
                icon="fal fa-images"
                :title="value.media_count | local_numbers"
                subtitle="Opslag"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex md6 sm12 xs12>
              <stat-widget
                icon="fal fa-eye"
                :title="impressions | local_numbers"
                subtitle="Impressions"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex md6 sm12 xs12>
              <stat-widget
                icon="fal fa-eye"
                :title="reach | local_numbers"
                subtitle="Reach"
                flat
                bordered
              ></stat-widget>
            </v-flex>

            <v-flex xs12>
              <v-card class="pie-chart" flat>
                <v-card-title>
                  <div>
                    <div class="headline">Følger lande</div>
                    <span class="subtitle-1 light-grey"
                      >Top 5 lande følgere kommer fra</span
                    >
                  </div>
                </v-card-title>
                <v-card-text class="d-flex align-center p-0">
                  <influencer-channel-follower-country-chart
                    :value="stats.countries.slice(0, 5)"
                    style="max-height: 250px"
                  ></influencer-channel-follower-country-chart>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12>
              <v-card class="pie-chart" flat>
                <v-card-title>
                  <div>
                    <div class="headline">Følger byer</div>
                    <span class="subtitle-1 light-grey"
                      >Top 5 byer følgere kommer fra</span
                    >
                  </div>
                </v-card-title>
                <v-card-text class="d-flex align-center p-0">
                  <influencer-channel-follower-city-chart
                    :value="stats.cities.slice(0, 5)"
                    style="max-height: 250px"
                  ></influencer-channel-follower-city-chart>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex sm12 md6 lg4>
              <v-card class="pie-chart" flat>
                <v-card-title>
                  <div>
                    <div class="headline">Følger kønsfordeling</div>
                    <span class="subtitle-1 light-grey"
                      >Kønsfordelingen af følgere</span
                    >
                  </div>
                </v-card-title>
                <v-card-text class="d-flex align-center p-0">
                  <influencer-channel-follower-gender-chart
                    :value="stats.gender_age"
                    style="max-height: 250px"
                  ></influencer-channel-follower-gender-chart>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex sm12 md6 lg8>
              <v-card class="pie-chart" flat>
                <v-card-title>
                  <div>
                    <div class="headline">Følger køns- og aldersfordeling</div>
                    <span class="subtitle-1 light-grey"></span>
                  </div>
                </v-card-title>
                <v-card-text class="d-flex align-center p-0">
                  <influencer-channel-follower-gender-age-chart
                    :value="stats.gender_age"
                    style="max-height: 300px"
                  ></influencer-channel-follower-gender-age-chart>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12>
              <v-card flat>
                <v-card-title>
                  <div>
                    <div class="headline">Daglig følger vækst</div>
                    <span class="subtitle-1 light-grey"></span>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <follower-growth-chart
                    min-height="300px"
                    :data="followerGrowthData"
                  ></follower-growth-chart>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import StatWidget from "../../widgets/StatWidget";
import InfluencerChannelFollowerCountryChart from "../../charts/InfluencerChannelFollowerCountryChart";
import InfluencerChannelFollowerCityChart from "../../charts/InfluencerChannelFollowerCityChart";
import InfluencerChannelFollowerGenderChart from "../../charts/InfluencerChannelFollowerGenderChart";
import FollowerGrowthChart from "../../charts/FollowerGrowthChart";
import InfluencerChannelFollowerGenderAgeChart from "../../charts/InfluencerChannelFollowerGenderAgeChart";
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions } from "vuex";

export default {
  components: {
    InfluencerChannelFollowerGenderAgeChart,
    FollowerGrowthChart,
    InfluencerChannelFollowerGenderChart,
    InfluencerChannelFollowerCityChart,
    InfluencerChannelFollowerCountryChart,
    StatWidget
  },
  filters: { local_numbers },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    stats: null,
    dateRange: null
  }),
  computed: {
    showing: {
      get() {
        return this.value !== null;
      },
      set(value) {
        if (!value) {
          this.$emit("input", null);
        } else {
          this.$emit("input", value);
        }
      }
    },
    impressions() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.impressions,
        0
      );
    },
    reach() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.unique_profile_views,
        0
      );
    },
    followerGrowthData() {
      let stats = this.stats.daily.slice(0, 13);
      let data = {
        dates: [],
        values: []
      };
      stats.forEach(value => {
        data.dates.push(value.date);
        data.values.push(value.followers);
      });
      data.dates.reverse();
      data.values.reverse();
      return data;
    }
  },
  watch: {
    value() {
      this.fetchStats();
    },
    dateRange() {
      this.fetchStats();
    }
  },
  methods: {
    ...mapActions("core", ["getInfluencerStats"]),

    fetchStats() {
      this.stats = null;
      if (this.value !== null) {
        let params = {
          influencer_channel_uuid: this.value.uuid
        };
        this.getInfluencerStats(params).then(response => {
          this.stats = response;
        });
      }
    },
    initDateRange() {
      this.dateRange = {
        startDate: this.$moment()
          .subtract(30, "day")
          .format("YYYY-MM-DD"),
        endDate: this.$moment().format("YYYY-MM-DD")
      };
    }
  },
  created() {
    this.initDateRange();
    this.fetchStats();
  }
};
</script>
